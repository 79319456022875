import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Query } from 'react-apollo';

import { AnimationContext } from '../../../../helpers/context';
import { Content as GridContent } from '../../../Layout/FullPageSection';
import { Citation, Citations } from '../../../Citations';
import Slider from '../../../Slider';
import CMS_QUERY from '../../../../graphql/cms.query';

import theme from '../../../../styles/theme';
import styles from './styles';

const Payday = ({ classes }) => {
  const matches = useMediaQuery(theme.breakpoints.up('fullPageOn'));
  const isAnimated =
    useContext(AnimationContext) === 'paydaySection' && matches;

  return (
    <>
      <Query query={CMS_QUERY}>
        {({ data }) => (
          <GridContent>
            <div className={isAnimated ? classes.animated : ''}>
              <Container maxWidth={false}>
                <Box
                  className={classes.container}
                  pt={[8, 6, 6]}
                  pb={[3, 2, 2]}
                >
                  <Box mb={[2, 2, 2]}>
                    <Typography variant="h4" component="h2" color="secondary">
                      <Typography variant="h4" component="span" color="primary">
                        Инновационный
                      </Typography>
                      <br />
                      для российского
                      <br />
                      рынка сервис{' '}
                      <Typography variant="h4" component="span" color="primary">
                        PayDay
                      </Typography>
                    </Typography>
                  </Box>
                  <Box mb={[2, 2, 2]} maxWidth={736}>
                    <Typography variant="subtitle1" color="secondary">
                      {data &&
                        data.innovations &&
                        data.innovations[0].description}
                    </Typography>
                  </Box>
                  <Box mb={[12, 9, 9]}>
                    <Citations>
                      <Citation>
                        {data &&
                          data.innovations &&
                          data.innovations[1].description}
                      </Citation>
                      <Citation withIcon>
                        {data &&
                          data.innovations &&
                          data.innovations[2].description}
                      </Citation>
                    </Citations>
                  </Box>
                </Box>
              </Container>
              <Box pb={[3, 3, 3]}>
                <Slider slidesToShow={matches ? 7 : 2} />
              </Box>
            </div>
          </GridContent>
        )}
      </Query>
    </>
  );
};

Payday.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Payday);
