import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';

import HeaderTop from '../HeaderTop';
import HeaderBody from '../../../components/HeaderClient/Header';
import {
  Header as GridHeader,
  Content as GridContent,
} from '../FullPageSection';

const Header = ({ handleFullpageScroll }) => (
  <>
    <GridHeader>
      <div>
        <Container maxWidth={false}>
          <HeaderTop handleFullpageScroll={handleFullpageScroll} />
        </Container>
      </div>
    </GridHeader>

    <GridContent>
      <HeaderBody handleFullpageScroll={handleFullpageScroll} />
    </GridContent>
  </>
);

Header.propTypes = {
  handleFullpageScroll: PropTypes.func.isRequired,
};

export default Header;
