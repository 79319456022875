import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  citations: {
    [theme.breakpoints.up('m')]: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      margin: '0 -15px',
    },
  },
  citation: {
    borderRadius: '16px',
    padding: 30,
    position: 'relative',
    marginTop: 20,
    background: theme.palette.background.darkLight,
    '&:first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.up('m')]: {
      width: '50%',
      margin: '0 15px',
    },
  },
  citationIcon: {
    width: 86,
    height: 68,
    right: 17,
    bottom: -41,
    position: 'absolute',
    backgroundSize: 'cover',
    background: "url('/images/icons/quotes.svg')",
    [theme.breakpoints.up('m')]: {
      width: 124,
      height: 98,
      right: 20,
      bottom: -49,
    },
  },
}));

export default useStyles;
