import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  marqueeDivider: {
    display: 'inline-block',
    padding: '0 50px',
  },
  marqueeText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.darkLight,
  },
  marqueeText_theme_accent: {
    color: theme.palette.text.primaryContrast,
  },
  container: {
    [theme.breakpoints.up('l')]: {
      minHeight: 800,
    },
  },
}));

export default useStyles;
