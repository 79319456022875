const styles = theme => ({
  '@keyframes slideUp': {
    from: { transform: 'translateY(300px)' },
    to: { transform: 'translateY(0)' },
  },
  animated: {
    animation: '$slideUp 1s ease',
    position: 'relative',
    animationFillMode: 'backwards',
  },
  animated_first: {
    animationDelay: '0',
  },
  animated_second: {
    animationDelay: '.25s',
  },
  animated_third: {
    animationDelay: '.5s',
  },
  animated_fourth: {
    animationDelay: '.75s',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '-15px',
    marginRight: '-15px',
    flexWrap: 'wrap',
    [theme.breakpoints.up('m')]: {
      flexWrap: 'nowrap',
    },
  },
  item: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '20px',
    borderRadius: '16px',
    width: '100%',
    background: theme.palette.background.paper,
    [theme.breakpoints.up('m')]: {
      marginBottom: '15px',
      width: '40%',
    },
  },
  item_large: {
    width: '100%',
    [theme.breakpoints.up('m')]: {
      width: '60%',
    },
  },
  itemFooter: {
    background: theme.palette.background.dark,
    color: theme.palette.primary.contrastText,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '24px',
    borderRadius: '16px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    [theme.breakpoints.up('m')]: {
      fontSize: '20px',
      lineHeight: '32px',
      padding: '30px',
    },
  },
  itemInner: {
    padding: '24px',
    [theme.breakpoints.up('m')]: {
      padding: '30px',
    },
  },
});

export default styles;
