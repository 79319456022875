export default theme => ({
  steps: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.app.separator}`,
    borderBottom: `1px solid ${theme.palette.app.separator}`,
    overflow: 'hidden',
    width: '100vw',
    position: 'relative',
    paddingBottom: 16,
    [theme.breakpoints.up('m')]: {
      paddingTop: 210,
      paddingBottom: 0,
      borderTop: 'none',
    },
  },
  stepsWrapper: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('l')]: {
      '&::after': {
        content: '""',
        position: 'absolute',
        displya: 'block',
        left: 0,
        top: 210,
        width: '100%',
        borderTop: `1px solid ${theme.palette.app.separator}`,
        borderBottom: `1px solid ${theme.palette.app.separator}`,
        height: 16,
      },
    },
    [theme.breakpoints.up('m')]: {
      padding: '16px 0 0',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        displya: 'block',
        left: 0,
        top: 210,
        width: '100%',
        borderTop: `1px solid ${theme.palette.app.separator}`,
        borderBottom: `1px solid ${theme.palette.app.separator}`,
        height: 16,
      },
    },
  },
  stepsArrowContainer: {
    position: 'relative',
  },
  stepsArrow: {
    [theme.breakpoints.up('l')]: {
      position: 'absolute',
      top: 150,
      right: 40,
      width: 291,
      height: 8,
      background: 'url("/images/icons/arrow.svg")',
      zIndex: 1000,
    },
  },
  stepsContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  stepsGrid: {
    [theme.breakpoints.up('m')]: {
      display: 'flex',
    },
    background: theme.palette.background.paper,
  },
  stepsFence: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.app.separator} 2px, transparent 2px), linear-gradient(90deg, ${theme.palette.app.separator} 1px, ${theme.palette.background.paper} 1px)`,
    backgroundSize: '17px 17px, 17px 17px',
    backgroundPosition: '-2px -2px, -2px -2px',
    flexShrink: 0,
    flexGrow: 0,
  },
  step: {
    borderTop: `1px solid ${theme.palette.app.separator}`,
    borderBottom: `1px solid ${theme.palette.app.separator}`,
    marginTop: '16px',
    '&:first-child': {
      marginTop: 0,
    },
    backgroundImage: `linear-gradient(90deg, ${theme.palette.app.separator} 2px, transparent 2px), linear-gradient(90deg, ${theme.palette.app.separator} 1px, ${theme.palette.background.paper} 1px)`,
    backgroundSize: '17px 17px, 17px 17px',
    backgroundPosition: '-2px -2px, -2px -2px',
    [theme.breakpoints.up('m')]: {
      borderRight: `1px solid ${theme.palette.app.separator}`,
      borderLeft: `1px solid ${theme.palette.app.separator}`,
      marginTop: 0,
      borderTop: 'none',
      borderBottom: 'none',
      marginRight: 32,
      width: 380,
      flexGrow: 0,
      flexShrink: 0,
      background: theme.palette.background.paper,
      backgroundImage: 'none',
    },
  },
  step_first: {
    borderLeft: 'none',
  },
  step_last: {
    borderRight: 'none',
    marginRight: 0,
  },
  stepContent: {
    padding: '72px 18px',
    [theme.breakpoints.up('l')]: {
      padding: '96px 32px',
    },
  },
  stepWrapper: {
    margin: '0 40px',
    background: theme.palette.background.paper,
    [theme.breakpoints.up('l')]: {
      margin: 0,
      background: 'none',
    },
  },
  stepTitle: {
    marginBottom: 16,
  },
  stepIndex: {
    position: 'relative',
    paddingBottom: 50,
  },
  stepIndexText_theme_filled: {
    fontSize: '60px',
    lineHeight: 1.2,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    position: 'relative',
    paddingBottom: 16,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  stepIndexText_theme_outlined: {
    position: 'absolute',
    fontSize: '60px',
    lineHeight: 1.2,
    color: theme.palette.background.paper,
    transform: 'translate(-5px, -5px)',
    '-webkit-text-stroke': '1px black',
    fontWeight: 'bold',
  },
});
