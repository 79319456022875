const styles = theme => ({
  wrapper: {
    display: 'block',
    [theme.breakpoints.up('l')]: {
      display: 'flex',
    },
  },
  logo: {
    display: 'block',
    height: '72px',
    [theme.breakpoints.up('m')]: {
      height: '96px',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  logo_payday: {
    height: '27px',
    paddingRight: '13px',
  },
  logo_vk: {
    borderLeft: '2px solid #AAAEB3',
    paddingLeft: '13px',
    height: '14px',
  },
  banner: {
    margin: '0 auto',
    flexGrow: 1,
    padding: '515px 49px 84px',
    background: '#3A3A3A',
    backgroundImage: `url("/images/vk-banner-image.png")`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '414px',
    [theme.breakpoints.up('l')]: {
      minWidth: '414px',
    },
  },
  vkDecor: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    height: '515px',
  },
  bannerText: {
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '34px',
    fontFamily: 'VKSans, Arial, sans-serif',
  },
  bannerButton: {
    fontFamily: 'VKSans, Arial, sans-serif',
    fontSize: '18px !important',
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export default styles;
