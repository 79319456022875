import React from 'react';
import { Query } from 'react-apollo';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import FaqComponent from '../../../Faq';

import CMS_QUERY from '../../../../graphql/cms.query';

const Faq = () => (
  <Container maxWidth={false}>
    <Box pt={[3, 3, 5]} pb={[8, 8, 5]}>
      <Query query={CMS_QUERY}>
        {({ data }) => (
          <FaqComponent items={(data && data.faqs_client) || []} />
        )}
      </Query>
    </Box>
  </Container>
);

export default Faq;
