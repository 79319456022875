const styles = theme => ({
  '@keyframes slideUp': {
    from: { transform: 'translateY(300px)' },
    to: { transform: 'translateY(0)' },
  },
  animated: {
    animation: '$slideUp 1s ease',
    animationFillMode: 'backwards',
  },
  container: {
    [theme.breakpoints.up('l')]: {
      minHeight: 742,
    },
  },
});

export default styles;
