import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Query } from 'react-apollo';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import handleScroll from '../../helpers/scrollToAnchor';

import theme from '../../styles/theme';
import CMS_QUERY from '../../graphql/cms.query';
import useStyles from './styles';

const Header = ({ handleFullpageScroll }) => {
  const classes = useStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.up('fullPageOn'));

  return (
    <div className={classNames(classes.header, 'qaHeaderSection')}>
      <Container maxWidth={false} classes={{ root: classes.container }}>
        <Box pt={[9.5, 9.5, 4]} pb={[9.5, 9.5, 2]}>
          <Query query={CMS_QUERY}>
            {({ data }) =>
              data && data.banners_client && data.banners_client.length ? (
                <div className={classes.headerGrid}>
                  <div className={classes.textContainer}>
                    <div className={classes.headerContent}>
                      <div className={classes.headerContentInner}>
                        <Typography variant="h1">
                          <div className={classes.headerText}>
                            {data.banners_client[0].title.split(' ')[0]}
                          </div>
                          <div className={classes.headerAccentText}>
                            {data.banners_client[0].title.split(' ')[1]}
                            <div className={classes.headerAccentMirror}>
                              {data.banners_client[0].title.split(' ')[1]}
                            </div>
                          </div>
                          <div className={classes.headerText}>
                            {data.banners_client[0].title
                              .split(' ')
                              .slice(2)
                              .join(' ')}
                          </div>
                        </Typography>
                        <Box pt={2} pb={5} maxWidth={590}>
                          <Typography variant="subtitle1">
                            {data.banners_client[0].description}
                          </Typography>
                        </Box>
                        <div>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              if (
                                matches &&
                                typeof handleFullpageScroll === 'function'
                              ) {
                                handleFullpageScroll();
                              } else {
                                handleScroll('form-anchor');
                              }
                            }}
                            className={classes.headerBodyBtn}
                          >
                            Узнать подробнее
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.imageContainer} />
                </div>
              ) : (
                <div className={classes.headerSpacer} />
              )
            }
          </Query>
        </Box>
      </Container>
    </div>
  );
};

Header.propTypes = {
  handleFullpageScroll: PropTypes.func.isRequired,
};

export default Header;
