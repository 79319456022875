const styles = theme => ({
  company: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('m')]: {
      flexDirection: 'row',
    },
  },
  companyContainer: {
    position: 'relative',
  },
  companyLogosBox: {
    flexGrow: 1,
  },
  companyLogosContainer: {
    height: '100%',
  },
  logo: {
    display: 'block',
    width: '100%',
  },
  companyEmployees: {
    color: theme.palette.text.primaryContrast,
    fontSize: '60px',
    lineHeight: '120%',
    letterSpacing: '-1.5px',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.up('l')]: {
      fontSize: '96px',
      lineHeight: '112px',
    },
  },
  companyLogos: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyItems: 'center',
  },
  fullWidthWrapper: {
    background: theme.palette.background.accent,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.background.dark,
    },
  },
  logo_OK: {
    transform: 'translateY(20%)',
  },
});

export default styles;
