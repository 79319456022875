import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../styles/theme';

import useStyles from './styles';

const Citations = ({ children }) => {
  const classes = useStyles(theme);

  return <div className={classes.citations}>{children}</div>;
};

Citations.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default Citations;
