import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const RoundText = ({ classes, position }) => (
  <div
    className={classNames(
      classes.roundTextContainer,
      classes[`roundTextContainer_position_${position}`]
    )}
  >
    <div className={classes.logoWrapper}>
      <img
        src="/images/logo/vk-logo-white.svg"
        alt=""
        className={classes.logo}
      />
    </div>
    <div
      className={classNames(
        classes.roundTextWrapper,
        classes.roundTextWrapper_lower
      )}
    >
      <div
        className={classNames(
          classes.textContainer,
          classes.textContainer_outer
        )}
      >
        <img
          src="/images/roundText/round-text-blue.svg"
          alt=""
          className={classes.img}
        />
      </div>
      <div
        className={classNames(
          classes.textContainer,
          classes.textContainer_inner
        )}
      >
        <img
          src="/images/roundText/round-text-black.svg"
          alt=""
          className={classes.img}
        />
      </div>
    </div>

    <div
      className={classNames(
        classes.roundTextWrapper,
        classes.roundTextWrapper_upper
      )}
    >
      <div
        className={classNames(
          classes.textContainer,
          classes.textContainer_outer
        )}
      >
        <img
          src="/images/roundText/round-text-white.svg"
          alt=""
          className={classes.img}
        />
      </div>
      <div
        className={classNames(
          classes.textContainer,
          classes.textContainer_inner
        )}
      >
        <img
          src="/images/roundText/round-text-blue.svg"
          alt=""
          className={classes.img}
        />
      </div>
    </div>
  </div>
);

RoundText.propTypes = {
  classes: PropTypes.object.isRequired,
  position: PropTypes.oneOf(['topright', 'bottomright']).isRequired,
};

export default withStyles(styles)(RoundText);
