import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import theme from '../../styles/theme';

import useStyles from './styles';

const Citation = ({ children, withIcon }) => {
  const classes = useStyles(theme);

  return (
    <div className={classes.citation}>
      <Typography variant="subtitle1" color="secondary">
        {children}
      </Typography>
      {withIcon && <div className={classes.citationIcon} />}
    </div>
  );
};

Citation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  withIcon: PropTypes.bool,
};

Citation.defaultProps = {
  withIcon: false,
};

export default Citation;
