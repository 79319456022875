import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import theme from '../../styles/theme';

import useStyles from './styles';

const icons = ['icon_piggy.svg', 'icon_up.svg', 'icon_settings.svg'];

const Profits = ({ profits }) => {
  const classes = useStyles(theme);

  return (
    <div className={classes.profits}>
      <div className={classes.profitsWrapper}>
        <Container className={classes.profitsContainer} maxWidth={false}>
          <div className={classes.profitsGrid}>
            {profits.map(({ id, title, description }, index) => (
              <div className={classes.profit} key={id}>
                <div className={classes.profitWrapper}>
                  <div className={classes.profitContent}>
                    <div className={classes.profitIcon}>
                      <img src={`/images/icons/${icons[index]}`} alt="Payday" />
                    </div>
                    <Typography
                      className={classes.profitTitle}
                      variant="h5"
                      color="secondary"
                    >
                      {title}
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {description}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

Profits.propTypes = {
  profits: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Profits;
