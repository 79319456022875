import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import { withStyles } from '@material-ui/core/styles';

const Demo = dynamic(() => import('./Demo'), { ssr: false });
import List from './List';

import block1 from './lotties/block1/block1.json';
import block_2 from './lotties/block_2/block_2.json';
import block_3 from './lotties/block_3/block_3.json';

import styles from './styles';

const animations = [
  {
    type: 'animation',
    data: block1,
  },
  {
    type: 'animation',
    data: block_2,
  },
  {
    type: 'animation',
    data: block_3,
  },
  {
    type: 'image',
    data: '/block4/sap.png',
  },
];

class Faq extends React.Component {
  state = { activeTabIndex: 0 };

  setActiveTab = index => {
    this.setState({ activeTabIndex: index });
  };

  render() {
    const { classes, items } = this.props;

    return (
      <div className={classes.grid}>
        <div className={classes.gridLeft}>
          <List
            data={items}
            activeTabIndex={this.state.activeTabIndex}
            onItemClick={this.setActiveTab}
            classes={classes}
          />
        </div>
        <div className={classes.gridRight}>
          <Demo
            demoData={animations[this.state.activeTabIndex]}
            classes={classes}
          />
        </div>
      </div>
    );
  }
}

Faq.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(Faq);
