import React from 'react';

import Box from '@material-ui/core/Box';

import {
  Content as GridContent,
  Header as GridHeader,
} from '../../../Layout/FullPageSection';
import theme from '../../../../styles/theme';
import AllCompany from '../../../AllCompany';
import AboutPayday from '../../../AboutPayday';
import RoundText from '../../../RoundText';

import useStyles from './styles';

const Mission = () => {
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <GridHeader>
        <AllCompany />
      </GridHeader>
      <GridContent>
        <AboutPayday />
      </GridContent>
      <Box pb={[28, 28, 0]} />
      <RoundText position="bottomright" />
    </div>
  );
};

export default Mission;
