const styles = theme => ({
  '@keyframes slideUp': {
    from: { transform: 'translateY(300px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },
  free: {
    background: theme.palette.background.accent,
  },
  animated: {
    animation: '$slideUp 1s ease',
    animationFillMode: 'backwards',
  },
});

export default styles;
