import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { AnimationContext } from '../../../../helpers/context';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FormComponent from '../../../Form';

import styles from './styles';
import theme from '../../../../styles/theme';

const Form = ({ classes }) => {
  const isAnimated =
    useContext(AnimationContext) === 'formSection' &&
    useMediaQuery(theme.breakpoints.up('fullPageOn'));

  return (
    <div
      className={classNames(
        'qaContactSection',
        isAnimated ? classes.animated : ''
      )}
    >
      <Container maxWidth={false} name="form-anchor">
        <Box pt={[8, 5, 3]} pb={[8, 5, 3]}>
          <Box pb={[3, 3, 7]}>
            <Typography variant="h3" color="secondary">
              Давайте
              <br /> знакомиться
              <Typography variant="h3" component="span" color="primary">
                ?
              </Typography>
            </Typography>
          </Box>
          <FormComponent buttonId="gtm-client-form-submit" customer="client" />
        </Box>
      </Container>
    </div>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  isAnimated: PropTypes.bool,
};

Form.defaultProps = {
  isAnimated: false,
};

export default withStyles(styles)(Form);
