import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  marquee: {
    overflow: 'hidden',
    display: 'flex',
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  '@keyframes scrollRightFirst': {
    from: { transform: 'translateX(0%)' },
    to: { transform: 'translateX(-100%)' },
  },
  '@keyframes scrollRightSecond': {
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0%)' },
  },
  '@keyframes scrollLeftFirst': {
    from: { transform: 'translateX(0%)' },
    to: { transform: 'translateX(100%)' },
  },
  '@keyframes scrollLeftSecond': {
    from: { transform: 'translateX(-100%)' },
    to: { transform: 'translateX(0%)' },
  },

  '@keyframes scrollRightFirst_withOffset': {
    from: { transform: 'translateX(calc(0% - 100px))' },
    to: { transform: 'translateX(calc(-100% - 100px))' },
  },
  '@keyframes scrollRightSecond_withOffset': {
    from: { transform: 'translateX(calc(100% - 100px))' },
    to: { transform: 'translateX(calc(0% - 100px))' },
  },
  '@keyframes scrollLeftFirst_withOffset': {
    from: { transform: 'translateX(calc(0% + 100px))' },
    to: { transform: 'translateX(calc(100% + 100px))' },
  },
  '@keyframes scrollLeftSecond_withOffset': {
    from: { transform: 'translateX(calc(-100% + 100px))' },
    to: { transform: 'translateX(calc(0% + 100px))' },
  },
  marqueeWrapper: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center !important',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  marqueeWrapper_child_first: {
    animationName: '$scrollRightFirst',
    '&$hasOffset': {
      animationName: '$scrollRightFirst_withOffset',
    },
  },
  marqueeWrapper_child_second: {
    animationName: '$scrollRightSecond',
    '&$hasOffset': {
      animationName: '$scrollRightSecond_withOffset',
    },
  },
  hasOffset: {
    display: 'block',
  },
  marqueeWrapper_direction_left: {
    left: 'auto',
    right: 0,
    '&$marqueeWrapper_child_first': {
      animationName: '$scrollLeftFirst',
    },
    '&$marqueeWrapper_child_second': {
      animationName: '$scrollLeftSecond',
    },
  },
}));

export default useStyles;
