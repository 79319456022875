const styles = theme => ({
  section_bg_white: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  section_bg_gray: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  section_bg_blue: {
    background: theme.palette.background.accent,
    color: theme.palette.text.primaryContrast,
  },
  section_bg_darkGray: {
    background: theme.palette.background.dark,
    color: theme.palette.text.primaryContrast,
  },
  contentWrapper: {
    position: 'relative',
    display: 'block',
    height: '100%',
    alignContent: 'center',
    [theme.breakpoints.up('fullPageOn')]: {
      display: 'grid',
    },
  },
  contentWrapper_verticalAlignTop: {
    alignContent: 'start',
  },
  contentWrapper_withHeader: {
    gridTemplateRows: 'auto 1fr',
  },
  contentWrapper_withFooter: {
    gridTemplateRows: '1fr auto',
  },
  content: {
    alignSelf: 'center',
    overflow: 'hidden',
  },
  header: {
    alignSelf: 'start',
  },
  footer: {
    alignSelf: 'end',
  },
});

export default styles;
