import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ClientStepsInner from './ClientStepsInner';
import styles from './styles';

class StepsMobile extends React.Component {
  render() {
    const { classes, steps } = this.props;

    return (
      <div className={classes.stepsWrapper}>
        <div className={classNames(classes.steps)}>
          <ClientStepsInner steps={steps} />
        </div>
      </div>
    );
  }
}

StepsMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
};

export default withStyles(styles)(StepsMobile);
