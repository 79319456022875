import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  slider: {
    position: 'relative',
  },
  sliderFader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 184,
    height: '100%',
    background:
      'linear-gradient(to right, rgba(28, 28, 30, 1), rgba(28, 28, 30, 0))',
    zIndex: 1000,
  },
  sliderItems: {
    display: 'flex',
    alignItems: 'center',
  },
  sliderItem: {
    margin: '0 30px',
    height: 48,
  },
  sliderFader_position_end: {
    left: 'auto',
    right: 0,
    background:
      'linear-gradient(to left, rgba(28, 28, 30, 1), rgba(28, 28, 30, 0))',
  },
}));

export default useStyles;
