import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Box from '@material-ui/core/Box';

import theme from '../../styles/theme';

import useStyles from './styles';

const Marquee = ({
  children,
  duration = 60,
  direction = 'right',
  height = 0,
  hasOffset,
}) => {
  const classes = useStyles(theme);

  return (
    <Box pt={height} className={classNames(classes.marquee)}>
      <div
        className={classNames(
          classes.marqueeWrapper,
          classes.marqueeWrapper_child_first,
          classes[`marqueeWrapper_direction_${direction}`],
          hasOffset && classes.hasOffset
        )}
        style={{
          animationDuration: `${duration}s`,
        }}
      >
        {children}
      </div>
      <div
        className={classNames(
          classes.marqueeWrapper,
          classes.marqueeWrapper_child_second,
          classes[`marqueeWrapper_direction_${direction}`],
          hasOffset && classes.hasOffset
        )}
        style={{
          animationDuration: `${duration}s`,
        }}
      >
        {children}
      </div>
    </Box>
  );
};

Marquee.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  duration: PropTypes.number,
  direction: PropTypes.oneOf(['right', 'left']),
  height: PropTypes.oneOf([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  hasOffset: PropTypes.bool,
};

Marquee.defaultProps = {
  duration: 60,
  direction: 'right',
  height: 0,
  hasOffset: false,
};

export default Marquee;
