const styles = theme => ({
  grid: {
    [theme.breakpoints.up('l')]: {
      display: 'flex',
    },
  },
  gridLeft: {
    [theme.breakpoints.up('m')]: {
      maxWidth: '600px',
      minHeight: '445px',
    },
    [theme.breakpoints.up('l')]: {
      minHeight: '780px',
    },
  },
  gridRight: {
    maxWidth: '500px',
    margin: '0 auto',
    [theme.breakpoints.up('l')]: {
      margin: 0,
    },
  },
  demoImage: {
    width: '100%',
  },
  list: {
    marginTop: '30px',
    listStyle: 'none',
    padding: 0,
    textAlign: 'left',
  },
  listItem: {
    paddingLeft: '24px',
    marginBottom: '24px',
    [theme.breakpoints.up('l')]: {
      marginBottom: '24px',
    },
    cursor: 'pointer',
  },
  listItemTitle: {
    [theme.breakpoints.up('m')]: {
      fontSize: '60px',
      lineHeight: '72px',
      transform: 'scale(0.4)',
      transformOrigin: 'left bottom',
      position: 'relative',
      transition: 'transform 700ms ease',
    },
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%)',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  listItemTitleActive: {
    display: 'none',
    whiteSpace: 'nowrap',
  },
  listItemTitleInactive: {
    [theme.breakpoints.up('m')]: {
      whiteSpace: 'nowrap',
    },
    display: 'block',
  },
  listItemAccent: {
    transition: 'color 300ms ease',
    color: theme.palette.text.primary,
  },
  listItemDescription: {
    maxHeight: 0,
    opacity: 0,
    transform: 'translateY(15px)',
    transition: 'opacity 600ms ease, max-height, transform ease 800ms',
  },
  listTitleWrapper: {
    position: 'relative',
  },
  listTitleBar: {
    position: 'absolute',
    background: theme.palette.primary.main,
    width: 6,
    zIndex: 100,
    left: -24,
    minHeight: 6,
    top: '50%',
    transform: 'translateY(0)',
    [theme.breakpoints.up('m')]: {
      transform: 'translateY(-15px) scaleY(0.08)',
      transformOrigin: 'center',
      height: '100%',
      transition: 'transform 700ms ease',
    },
  },
  listItemActive: {
    cursor: 'default',
    [theme.breakpoints.up('m')]: {
      marginTop: 50,
    },
    '& $listTitleBar': {
      minHeight: '100%',
      transform: 'translateY(-50%)',
      transition: 'min-height 700ms ease',
      [theme.breakpoints.up('m')]: {
        transform: 'translateY(-50%) scaleY(1)',
        transition: 'transform 700ms ease',
      },
    },
    '& $listItemTitle': {
      [theme.breakpoints.up('m')]: {
        fontSize: '60px',
        lineHeight: '72px',
        height: '72px',
        paddingTop: 20,
        transformOrigin: 'left top',
      },
      [theme.breakpoints.up('l')]: {
        transform: 'scale(1)',
        height: '144px',
        paddingTop: 0,
        transformOrigin: 'left bottom',
      },
    },
    '& $listItemTitleActive': {
      display: 'block',
    },
    '& $listItemTitleInactive': {
      display: 'none',
    },
    '& $listItemDescription': {
      maxHeight: '500px',
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'opacity 700ms ease, max-height, transform 700ms ease',
    },
    '& $listItemAccent': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    height: 48,
    marginRight: 20,
  },
});

export default styles;
