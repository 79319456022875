import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import ListItem from './ListItem';

// eslint-disable-next-line react/prop-types
const List = ({ data, activeTabIndex, onItemClick, classes }) => (
  <ul className={classes.list}>
    {/* eslint-disable-next-line react/prop-types */}
    {data.map(({ id, title, description }, index) => (
      <li key={id}>
        <ListItem
          isActive={index === activeTabIndex}
          title={title}
          description={
            index === data.length - 1 ? (
              <div>
                <Box mb={5}>{description}</Box>
                <Box display="flex">
                  <img src="/images/1C.svg" className={classes.icon} alt="1С" />
                  <img
                    src="/images/SAP.svg"
                    className={classes.icon}
                    alt="SAP"
                  />
                </Box>
              </div>
            ) : (
              description
            )
          }
          onClick={() => onItemClick(index)}
          classes={classes}
        />
      </li>
    ))}
  </ul>
);

List.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
