import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    background: theme.palette.app.header.client.background,
    overflow: 'hidden',
  },
  headerContent: {
    position: 'relative',
  },
  headerContentInner: {
    width: '100%',
    position: 'relative',
  },
  headerText: {
    fontSize: '60px',
    lineHeight: '70px',
    [theme.breakpoints.up('m')]: {
      fontSize: '112px',
      lineHeight: '130px',
    },
  },
  headerAccentText: {
    color: theme.palette.primary.main,
    position: 'relative',
    transform: 'translateY(-10px)',
    [theme.breakpoints.up('m')]: {
      transform: 'translateY(-15px)',
    },
    [theme.breakpoints.up('l')]: {
      transform: 'translateY(-20px)',
    },
  },
  headerAccentMirror: {
    position: 'absolute',
    transform: 'matrix(1, 0, 0, -1, 0, 0)',
    top: '46px',
    [theme.breakpoints.up('m')]: {
      top: '120px',
    },
    lineHeight: 1,
    zIndex: 1,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 30%, rgba(34, 109, 254, 0.2) 80%, rgba(34, 109, 254, 0.2) 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  ctaTextWrapper: {
    [theme.breakpoints.up('m')]: {
      maxWidth: '300px',
    },
  },
  headerGrid: {
    display: 'flex',
    width: '100%',
  },
  imageContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '50%',
    height: '730px',
    backgroundImage: 'url("/images/banner.webp")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
    zIndex: 1,
    display: 'none',
    [theme.breakpoints.up('l')]: {
      display: 'block',
    },
  },
  headerBodyBtn: {
    width: '100% !important',
    [theme.breakpoints.up('m')]: {
      width: 'auto !important',
    },
  },
  headerSpacer: {
    minHeight: {
      minHeight: '400px',
    },
    [theme.breakpoints.up('l')]: {
      minHeight: '700px',
    },
  },
}));

export default useStyles;
