import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from './styles';
import theme from '../../styles/theme';

const CompanySlogan = ({ classes }) => {
  const matches = useMediaQuery(theme.breakpoints.up('l'));

  return (
    <>
      <Container maxWidth={false}>
        <Box pt={[8, 14, 14]} pb={[8, 14, 14]}>
          <Container maxWidth={false}>
            <div className={classes.wrapper}>
              <Box pr={[0, 0, 2]}>
                <Box mb={[3, 5, 5]}>
                  <img
                    src="/images/logo/vk-logo-white.svg"
                    alt=""
                    className={classes.logo}
                  />
                </Box>

                <Typography variant="h4" color="primaryContrast">
                  Мы компания с сильной{' '}
                  <span className={classes.nowrap}>IT-составляющей</span> и
                  можем адаптировать свое решение под особенности вашего бизнеса
                </Typography>
              </Box>
              {matches && (
                <div className={classes.banner}>
                  <img
                    className={classes.vkDecor}
                    src="/images/VK-decor.svg"
                    alt=""
                  />
                  <Box mb={3.9} mt={7.5} display="flex" alignItems="center">
                    <img
                      src="/images/logo/payday-logo.svg"
                      alt=""
                      className={classes.logo_payday}
                    />
                    <img
                      src="/images/logo/VK-company-logo.svg"
                      alt=""
                      className={classes.logo_vk}
                    />
                  </Box>
                  <Box mb={2.6}>
                    <div className={classes.bannerText}>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Теперь PayDay —
                      </span>
                      <br />
                      часть экосистемы VK{' '}
                    </div>
                  </Box>
                  <Button
                    className={classes.bannerButton}
                    color="primary"
                    variant="contained"
                    fullWidth
                    component={Link}
                    href="https://vk.company/ru/press/releases/11015/"
                    target="_blank"
                  >
                    Подробнее
                  </Button>
                </div>
              )}
            </div>
          </Container>
        </Box>
      </Container>
      {!matches && (
        <Box pb={[0, 8, 0]}>
          <div className={classes.banner}>
            <img
              className={classes.vkDecor}
              src="/images/VK-decor.svg"
              alt=""
            />
            <Box mb={3.9} mt={7.5} display="flex" alignItems="center">
              <img
                src="/images/logo/payday-logo.svg"
                alt=""
                className={classes.logo_payday}
              />
              <img
                src="/images/logo/VK-company-logo.svg"
                alt=""
                className={classes.logo_vk}
              />
            </Box>
            <Box mb={2.6}>
              <div className={classes.bannerText}>
                <span style={{ whiteSpace: 'nowrap' }}>Теперь PayDay —</span>
                <br />
                часть экосистемы VK{' '}
              </div>
            </Box>
            <Button
              className={classes.bannerButton}
              color="primary"
              variant="contained"
              fullWidth
              component={Link}
              href="https://vk.company/ru/press/releases/11015/"
              target="_blank"
            >
              Подробнее
            </Button>
          </div>
        </Box>
      )}
    </>
  );
};

CompanySlogan.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompanySlogan);
