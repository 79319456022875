import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const FullPageSection = ({
  classes,
  children,
  isAutoHeight,
  bgColor,
  hasHeader,
  hasFooter,
  isAlignedTop,
  zIndex,
}) => (
  <div
    className={classNames(
      'section',
      'fp-auto-height-responsive',
      isAutoHeight && 'fp-auto-height',
      classes[`section_bg_${bgColor}`]
    )}
    style={{ zIndex: zIndex }}
  >
    <div
      className={classNames(
        classes.contentWrapper,
        hasHeader && classes.contentWrapper_withHeader,
        hasFooter && classes.contentWrapper_withFooter,
        isAlignedTop && classes.contentWrapper_verticalAlignTop
      )}
    >
      {children}
    </div>
  </div>
);

FullPageSection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  isAutoHeight: PropTypes.bool,
  bgColor: PropTypes.oneOf(['white', 'blue', 'darkGray', 'gray']),
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
  isAlignedTop: PropTypes.bool,
  zIndex: PropTypes.number,
};

FullPageSection.defaultProps = {
  isAutoHeight: false,
  isCentered: true,
  bgColor: 'white',
  hasHeader: false,
  hasFooter: false,
  isAlignedTop: false,
  zIndex: 0,
};

export default withStyles(styles)(FullPageSection);
