import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// eslint-disable-next-line react/prop-types
const ListItem = ({ isActive, title, description, onClick, classes }) => {
  const words = title.split(' ');

  words[1] = <span className={classes.listItemAccent}>{words[1]}</span>;
  words[words.length - 2] = (
    <>
      {words[words.length - 2]}
      <br />
    </>
  );

  return (
    <div
      onClick={onClick}
      className={classNames(
        classes.listItem,
        isActive ? classes.listItemActive : ''
      )}
    >
      <div className={classes.listTitleWrapper}>
        <div className={classes.listTitleBar} />
        <div className={classes.listItemTitle}>
          <div className={classes.listItemTitleInactive}>{title}</div>
          <div className={classes.listItemTitleActive}>
            {words[0]}{' '}
            <span className={classes.listItemAccent}>{words[1]}</span>{' '}
            {words.slice(2).join(' ')}
          </div>
        </div>
      </div>
      <div className={classes.listItemDescription}>
        <Box pt={[1.6, 2, 3.2]}>
          <Typography variant="subtitle1">{description}</Typography>
        </Box>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default ListItem;
