import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const ClientStepsInner = ({ classes, steps, padding }) => (
  <div>
    <div className={classes.stepsContainer}>
      <div className={classes.stepsGrid}>
        <div className={classes.stepsFence} style={{ width: padding }} />
        {steps.map(({ id, title, description }, index) => (
          <div
            className={classNames(
              classes.step,
              !index && classes.step_first,
              index === steps.length - 1 && classes.step_last
            )}
            key={id}
          >
            <div className={classes.stepWrapper}>
              <div className={classes.stepContent}>
                <div className={classes.stepIndex}>
                  <span
                    className={classes.stepIndexText_theme_outlined}
                  >{`0${index + 1}`}</span>
                  <span
                    className={classes.stepIndexText_theme_filled}
                  >{`0${index + 1}`}</span>
                </div>
                <Typography className={classes.stepTitle} variant="h5">
                  {title}
                </Typography>
                <Typography variant="body1">{description}</Typography>
              </div>
            </div>
          </div>
        ))}
        <div className={classes.stepsFence} style={{ width: padding }} />
      </div>
    </div>
  </div>
);

ClientStepsInner.propTypes = {
  steps: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  padding: PropTypes.number,
};

ClientStepsInner.defaultProps = {
  padding: 40,
};

export default withStyles(styles)(ClientStepsInner);
