import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { AnimationContext } from '../../../../helpers/context';
import theme from '../../../../styles/theme';
import styles from './styles';

const Free = ({ classes }) => {
  const matches = useMediaQuery(theme.breakpoints.up('fullPageOn'));
  const isAnimated =
    useContext(AnimationContext) === 'paydaySection' && matches;

  return (
    <Box pt={[5, 10, 16]} pb={[5, 10, 16]} className={classes.free}>
      <div className={isAnimated ? classes.animated : ''}>
        <Container maxWidth={false}>
          <Typography
            variant="h4"
            align={matches ? 'center' : 'left'}
            color="secondary"
          >
            Сервис бесплатный для работодателя
          </Typography>
        </Container>
      </div>
    </Box>
  );
};

Free.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Free);
