import React from 'react';
import PropTypes from 'prop-types';

import StepsDesktop from './StepsDesktop';
import StepsMobile from './StepsMobile';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../styles/theme';

const Steps = ({ steps }) => {
  const matches = useMediaQuery(theme.breakpoints.up('m'));

  return matches ? (
    <StepsDesktop steps={steps} />
  ) : (
    <StepsMobile steps={steps} />
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Steps;
