import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

import PerfectScrollbar from '../../utils/PerfectScrollbar';
import ClientStepsInner from './ClientStepsInner';
import styles from './styles';

class StepsDesktop extends React.Component {
  constructor(props) {
    super(props);

    this.scrollContainer = React.createRef();
    this.container = React.createRef();
    this.scrollbar = null;
    this.state = {
      padding: 40,
    };
  }

  setPadding = padding => this.setState({ padding });

  updateScrollbar = () => {
    this.scrollbar.update();
    this.setPadding(this.container.current.getBoundingClientRect().left + 40);
  };

  componentDidMount() {
    this.setPadding(this.container.current.getBoundingClientRect().left + 40);

    if (this.scrollContainer && this.scrollContainer.current) {
      this.scrollbar = new PerfectScrollbar(this.scrollContainer.current, {
        handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
        wheelPropagation: true,
        useBothWheelAxes: true,
      });
    }

    window.addEventListener('resize', this.updateScrollbar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScrollbar);

    this.scrollbar.destroy();
    this.scrollbar = null;
  }

  render() {
    const { classes, steps } = this.props;
    return (
      <div className={classes.stepsWrapper}>
        <Container
          ref={this.container}
          className={classes.stepsArrowContainer}
          maxWidth={false}
        >
          <div className={classes.stepsArrow} />
        </Container>
        <div
          ref={this.scrollContainer}
          className={classNames(classes.steps, 'horizontal-scroll')}
        >
          <ClientStepsInner steps={steps} padding={this.state.padding} />
        </div>
      </div>
    );
  }
}

StepsDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(StepsDesktop);
