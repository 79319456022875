const styles = theme => ({
  separator: {
    height: '2px',
    background: theme.palette.common.white,
    width: '110px',
  },
  text: {
    fontSize: '20px',
    lineHeight: '32px',
  },
  textAccent: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  textBox: {
    maxWidth: 'none',
    [theme.breakpoints.up('l')]: {
      maxWidth: '50%',
    },
  },
});

export default styles;
