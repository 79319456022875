import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import FullPageSection from '../components/Layout/FullPageSection';
import Metadata from '../components/Layout/Metadata';
import Gql from '../components/Layout/Gql';
import Header from '../components/Layout/HeaderFullPage';
import Footer from '../components/Layout/Footer';
import NormalPageScrollUpButton, {
  FullPageScrollUpButton,
} from '../components/Layout/ScrollUpButton';

import Payday from '../components/pageSections/Client/Payday/Payday';
import Free from '../components/pageSections/Client/Free/Free';
import Faq from '../components/pageSections/Client/Faq/Faq';
import Profits from '../components/pageSections/Client/Profits/Profits';
import WhatDone from '../components/pageSections/Client/WhatDone/WhatDone';
import Company from '../components/pageSections/Client/Company/Company';
import Mission from '../components/pageSections/Client/Mission/Mission';
import Steps from '../components/pageSections/Client/Steps/Steps';
import Form from '../components/pageSections/Client/Form/Form';
import theme from '../styles/theme';
import { AnimationContext, ThemeContext } from '../helpers/context';

const scrollingSpeed = 700;

const anchors = [
  'headerSection',
  'paydaySection',
  'faqSection',
  'profitsSection',
  'whatDoneSection',
  'companySection',
  'missionSection',
  'stepsSection',
  'formSection',
];

class ClientFullPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animatedSectionName: null,
      showFullpageScrollUpBtn: false,
    };
  }

  render() {
    return (
      <ThemeContext.Provider value="dark">
        <AnimationContext.Provider value={this.state.animatedSectionName}>
          <Metadata>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Gql>
                <div className="fullpage-layout-wrapper">
                  <FullPageScrollUpButton
                    handleClick={() => {
                      window.fullpage_api.moveTo('headerSection');
                    }}
                    isVisible={this.state.showFullpageScrollUpBtn}
                  />
                  <ReactFullpage
                    responsiveWidth={1024}
                    verticalCentered={false}
                    anchors={anchors}
                    lockAnchors
                    scrollOverflow={true}
                    scrollOverflowOptions={{
                      preventDefault: false,
                      scrollX: true,
                    }}
                    scrollingSpeed={scrollingSpeed}
                    onLeave={(origin, destination, direction) => {
                      if (origin.anchor === 'formSection') {
                        const scrollInstances =
                          window.fp_scrolloverflow.iscrollHandler
                            .iScrollInstances;

                        if (scrollInstances && scrollInstances.length) {
                          scrollInstances[scrollInstances.length - 1].scrollTo(
                            0,
                            0
                          );
                        }
                      }

                      if (direction === 'down') {
                        this.setState({
                          animatedSectionName: destination.anchor,
                        });
                        if (origin.anchor === 'headerSection') {
                          this.setState({ showFullpageScrollUpBtn: true });
                        }
                      } else {
                        this.setState({
                          animatedSectionName: null,
                        });
                        if (destination.anchor === 'headerSection') {
                          this.setState({ showFullpageScrollUpBtn: false });
                        }
                      }
                    }}
                    render={({ fullpageApi }) => {
                      return (
                        <>
                          <FullPageSection hasHeader isAutoHeight>
                            <Header
                              handleFullpageScroll={() => {
                                fullpageApi.moveTo('formSection');
                              }}
                            />
                          </FullPageSection>
                          <FullPageSection bgColor="darkGray" isAutoHeight>
                            <Payday />
                            <Free />
                          </FullPageSection>
                          <FullPageSection>
                            <Faq />
                          </FullPageSection>
                          <FullPageSection isAlignedTop bgColor="darkGray">
                            <Profits />
                          </FullPageSection>
                          <FullPageSection>
                            <WhatDone />
                          </FullPageSection>
                          <FullPageSection bgColor="darkGray">
                            <Company />
                          </FullPageSection>
                          <FullPageSection
                            bgColor="darkGray"
                            hasHeader
                            zIndex={1}
                            isAutoHeight
                          >
                            <Mission />
                          </FullPageSection>
                          <FullPageSection bgColor="gray">
                            <Steps />
                          </FullPageSection>
                          <FullPageSection bgColor="darkGray" zIndex={-1}>
                            <Form />
                            <Footer />
                          </FullPageSection>
                        </>
                      );
                    }}
                  />
                  <Box display={{ s: 'block', m: 'none' }}>
                    <NormalPageScrollUpButton />
                  </Box>
                </div>
              </Gql>
            </ThemeProvider>
          </Metadata>
        </AnimationContext.Provider>
      </ThemeContext.Provider>
    );
  }
}

export default ClientFullPage;
