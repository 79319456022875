import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { withStyles } from '@material-ui/core/styles';
import { AnimationContext } from '../../../../helpers/context';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import theme from '../../../../styles/theme';
import CMS_QUERY from '../../../../graphql/cms.query';
import styles from './styles';

const WhatDone = ({ classes }) => {
  const isAnimated =
    useContext(AnimationContext) === 'whatDoneSection' &&
    useMediaQuery(theme.breakpoints.up('fullPageOn'));

  return (
    <Container maxWidth={false}>
      <Box pt={[3]} pb={[3]}>
        <Box mb={[2, 2, 4]}>
          <Typography variant="h4" component="h2">
            Что мы{' '}
            <Typography variant="h4" component="span" color="primary">
              сделали
            </Typography>
          </Typography>
        </Box>

        <Query query={CMS_QUERY}>
          {({ data }) => (
            <div className={classes.content}>
              {data && data.accomplishments && (
                <>
                  <div className={classes.row}>
                    <div
                      className={classNames(
                        classes.animated_first,
                        isAnimated && classes.animated,
                        classes.item,
                        classes.item_large
                      )}
                    >
                      <div className={classes.itemInner}>
                        <Box mb={[1.6]}>
                          <Typography variant="h5">
                            {data.accomplishments[0].title}
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.accomplishments[0].description,
                            }}
                          />
                        </Typography>
                      </div>
                      <div className={classes.itemFooter}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.accomplishments[0].subtitle,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={classNames(
                        classes.animated_second,
                        isAnimated && classes.animated,
                        classes.item
                      )}
                    >
                      <div className={classes.itemInner}>
                        <Box mb={[1.6]}>
                          <Typography variant="h5">
                            {data.accomplishments[1].title}
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.accomplishments[1].description,
                            }}
                          />
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div
                      className={classNames(
                        classes.animated_third,
                        isAnimated && classes.animated,
                        classes.item
                      )}
                    >
                      <div className={classes.itemInner}>
                        <Box mb={[1.6]}>
                          <Typography variant="h5">
                            {data.accomplishments[2].title}
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.accomplishments[2].description,
                            }}
                          />
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        classes.animated_fourth,
                        isAnimated && classes.animated,
                        classes.item,
                        classes.item_large
                      )}
                    >
                      <div className={classes.itemInner}>
                        <Box mb={[1.6]}>
                          <Typography variant="h5">
                            {data.accomplishments[3].title}
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.accomplishments[3].description,
                            }}
                          />
                        </Typography>
                      </div>
                      <div className={classes.itemFooter}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.accomplishments[3].subtitle,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Query>
      </Box>
    </Container>
  );
};

WhatDone.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhatDone);
