import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

export const Content = withStyles(styles)(({ classes, children }) => (
  <div className={classes.content}>{children}</div>
));
export const Header = withStyles(styles)(({ classes, children }) => (
  <div className={classes.header}>{children}</div>
));
export const Footer = withStyles(styles)(({ classes, children }) => (
  <div className={classes.footer}>{children}</div>
));
