import React from 'react';
import classNames from 'classnames';

import Marquee from '../Marquee';
import theme from '../../styles/theme';

import useStyles from './styles';

const icons = [
  'VK-main.svg',
  'Youla.svg',
  'DC.svg',
  'VK.svg',
  'VTB.svg',
  'Raiff.svg',
  'SDEK.svg',
  'GRAN.svg',
  'Monetka.svg',
  'OK.svg',
  'PIK.svg',
];

const Slider = () => {
  const classes = useStyles(theme);

  return (
    <div className={classes.slider}>
      <div className={classes.sliderFader} />
      <div
        className={classNames(
          classes.sliderFader,
          classes.sliderFader_position_end
        )}
      />
      <Marquee height={7} duration={100}>
        <div className={classes.sliderItems}>
          {icons.map(icon => (
            <div key={`${icon}`} className={classes.sliderItem}>
              <img height="48" src={`/images/slider/${icon}`} />
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
};

export default Slider;
