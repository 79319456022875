import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  profits: {
    background: theme.palette.background.dark,
    padding: '16px 0',
    borderTop: `1px solid ${theme.palette.background.darkLight}`,
    borderBottom: `1px solid ${theme.palette.background.darkLight}`,
  },
  profitsWrapper: {
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.up('l')]: {
      backgroundImage: `linear-gradient(90deg, ${theme.palette.background.darkLight} 2px, transparent 2px), linear-gradient(90deg, ${theme.palette.background.darkLight} 1px, ${theme.palette.background.dark} 1px)`,
      backgroundSize: '17px 17px, 17px 17px',
      backgroundPosition: '-2px -2px, -2px -2px',
      borderTop: `1px solid ${theme.palette.background.darkLight}`,
      borderBottom: `1px solid ${theme.palette.background.darkLight}`,
    },
  },
  profitsContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('l')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  profitsGrid: {
    [theme.breakpoints.up('l')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    background: theme.palette.background.dark,
  },
  profit: {
    borderTop: `1px solid ${theme.palette.background.darkLight}`,
    borderBottom: `1px solid ${theme.palette.background.darkLight}`,
    marginTop: '16px',
    '&:first-child': {
      marginTop: 0,
    },
    backgroundImage: `linear-gradient(90deg, ${theme.palette.background.darkLight} 2px, transparent 2px), linear-gradient(90deg, ${theme.palette.background.darkLight} 1px, ${theme.palette.background.dark} 1px)`,
    backgroundSize: '17px 17px, 17px 17px',
    backgroundPosition: '-2px -2px, -2px -2px',
    [theme.breakpoints.up('l')]: {
      borderRight: `1px solid ${theme.palette.background.darkLight}`,
      borderLeft: `1px solid ${theme.palette.background.darkLight}`,
      marginTop: 0,
      borderTop: 'none',
      borderBottom: 'none',
      marginRight: 32,
      maxWidth: '33%',
      flexBasis: '33%',
      background: theme.palette.background.dark,
      backgroundImage: 'none',
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
        marginRight: 0,
      },
    },
  },
  profitContent: {
    padding: '72px 32px 48px 32px',
  },
  profitWrapper: {
    margin: '0 40px',
    background: theme.palette.background.dark,
    [theme.breakpoints.up('l')]: {
      margin: 0,
      background: 'none',
    },
  },
  profitTitle: {
    marginBottom: 16,
  },
  profitIcon: {
    overflow: 'hidden',
    width: '72px',
    paddingLeft: '7px',
    paddingTop: '5px',
    height: '72px',
    marginBottom: '32px',
    background:
      'linear-gradient(180deg, #ffffff 0%, #ffffff 29.05%, #e2e6eb 100%)',
    boxShadow:
      'inset -13px -13px 55px rgba(0, 0, 0, 0.07), inset -26px -23px 55px rgba(0, 0, 0, 0.06), inset -21px -22px 54px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px',
  },
}));

export default useStyles;
