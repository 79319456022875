import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('l')]: {
      minHeight: 890,
    },
  },
}));

export default useStyles;
