const styles = () => ({
  '@keyframes slideDown': {
    from: { transform: 'translateY(-300px)' },
    to: { transform: 'translateY(0)' },
  },
  animated: {
    animation: '$slideDown 700ms ease',
    position: 'relative',
    animationFillMode: 'backwards',
  },
});

export default styles;
