import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('l')]: {
      minHeight: 1018,
    },
  },
  titleContainer: {
    position: 'relative',
  },
  title: {
    [theme.breakpoints.up('m')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1000,
    },
  },
}));

export default useStyles;
