import React from 'react';
import { Query } from 'react-apollo';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ProfitsList from '../../../Profits';
import Marquee from '../../../Marquee';

import theme from '../../../../styles/theme';
import CMS_QUERY from '../../../../graphql/cms.query';

import useStyles from './styles';

const titles = [
  'Экономия',
  'Автоматизация',
  'Мотивация',
  'Снижение текучести',
  'Новый уровень',
];

const Profits = () => {
  const classes = useStyles(theme);

  return (
    <Box className={classes.container} pb={3}>
      <Box pt={[8, 0, 0]} pb={3}>
        <Marquee height={[3, 11, 11]} duration={200} hasOffset>
          <Typography
            variant="h3"
            component="div"
            className={classes.marqueeText}
          >
            {titles.map(title => (
              <>
                {title}
                <Typography
                  className={classes.marqueeDivider}
                  variant="h4"
                  component="span"
                >
                  ▶︎
                </Typography>
              </>
            ))}
          </Typography>
        </Marquee>
        <Marquee height={[3, 11, 11]} duration={300}>
          <Typography
            variant="h3"
            component="div"
            className={classes.marqueeText}
          >
            {titles.map(title => (
              <>
                {title}
                <Typography
                  className={classes.marqueeDivider}
                  variant="h4"
                  component="span"
                >
                  ▶︎
                </Typography>
              </>
            ))}
          </Typography>
        </Marquee>
      </Box>
      <Query query={CMS_QUERY}>
        {({ data }) => (
          <ProfitsList profits={(data && data.advantages_client) || []} />
        )}
      </Query>
    </Box>
  );
};

export default Profits;
