import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import theme from '../../styles/theme';
import styles from './styles';

const AllCompany = ({ classes }) => {
  const matches = useMediaQuery(theme.breakpoints.up('m'));

  return (
    <div className={classes.fullWidthWrapper}>
      <Container
        maxWidth={false}
        disableGutters={!matches}
        className={classes.companyContainer}
      >
        <div className={classes.company}>
          <Box
            pt={[8, 11, 11]}
            pb={[8, 11, 11]}
            pr={[0, 7, 7]}
            bgcolor={theme.palette.background.accent}
          >
            <Container maxWidth={false} disableGutters={!!matches}>
              <div className={classes.companyEmployees}>
                9 тыс.
                <br />
                человек
              </div>
            </Container>
          </Box>
          <Box
            pt={[5, 0, 0]}
            pl={[0, 7, 7]}
            className={classes.companyLogosBox}
            bgcolor={theme.palette.background.dark}
          >
            <Container
              maxWidth={false}
              disableGutters={!!matches}
              className={classes.companyLogosContainer}
            >
              <div className={classes.companyLogos}>
                <Box p={[3, 1, 1]}>
                  <img
                    className={classes.logo}
                    src="/images/logo/DC-logo.svg"
                    alt=""
                  />
                </Box>
                <Box p={[3, 1, 1]}>
                  <img
                    className={classes.logo}
                    src="/images/logo/VK-logo.svg"
                    alt=""
                  />
                </Box>
                <Box p={[3, 1, 1]}>
                  <img
                    className={classes.logo}
                    src="/images/logo/youla-logo.svg"
                    alt=""
                  />
                </Box>
                <Box p={[3, 1, 1]}>
                  <img
                    className={classNames(classes.logo, classes.logo_OK)}
                    src="/images/logo/OK-logo.svg"
                    alt=""
                  />
                </Box>
              </div>
            </Container>
          </Box>
        </div>
      </Container>
    </div>
  );
};

AllCompany.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AllCompany);
