import React from 'react';
import { Query } from 'react-apollo';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import RoundText from '../../../RoundText/RoundText';
import theme from '../../../../styles/theme';
import ClientSteps from '../../../ClientSteps';
import CMS_QUERY from '../../../../graphql/cms.query';

import useStyles from './styles';

const Steps = () => {
  const matches = useMediaQuery(theme.breakpoints.up('m'));
  const classes = useStyles(theme);

  return (
    <Box className={classes.container} pt={[24, 24, 14]} pb={[8, 8, 14]}>
      <Container maxWidth={false}>
        {matches && (
          <>
            <Box pt={[28, 28, 0]} />
            <RoundText position="topright" />
          </>
        )}
        <div className={classes.titleContainer}>
          <Box mb={7} className={classes.title}>
            <Typography variant="h4" component="h2">
              Как{' '}
              <Typography variant="h4" component="span" color="primary">
                получить
              </Typography>
              {matches ? <br /> : ' '}
              продукт
            </Typography>
          </Box>
        </div>
      </Container>
      <Query query={CMS_QUERY}>
        {({ data }) => <ClientSteps steps={(data && data.products) || []} />}
      </Query>
    </Box>
  );
};

export default Steps;
