const styles = theme => ({
  '@keyframes rotateText': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  '@keyframes rotateText_withOffset': {
    from: { transform: 'rotate(30deg)' },
    to: { transform: 'rotate(390deg)' },
  },
  roundTextContainer: {
    position: 'absolute',
    zIndex: 1,
    height: '430px',
    width: '430px',
    [theme.breakpoints.up('m')]: {
      height: '500px',
      width: '500px',
    },
  },
  roundTextContainer_position_topright: {
    right: 0,
    top: 0,
    bottom: 'auto',
    transform: 'translate(20%, -70%)',
  },
  roundTextContainer_position_bottomright: {
    right: 0,
    bottom: 0,
    top: 'auto',
    transform: 'translate(20%, 30%)',
  },
  textContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
  },
  textContainer_inner: {
    height: '330px',
    [theme.breakpoints.up('m')]: {
      height: '380px',
    },
    '& $img': {
      animationName: '$rotateText_withOffset',
      animationDuration: '30s',
    },
  },
  img: {
    animationDuration: '40s',
    animationName: '$rotateText',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    height: '100%',
  },
  roundTextWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: '430px',
    width: '430px',
    [theme.breakpoints.up('m')]: {
      height: '500px',
      width: '500px',
    },
  },
  roundTextWrapper_upper: {
    clipPath: 'inset(0 0 30% 0)',
  },
  roundTextWrapper_lower: {
    clipPath: 'inset(70% 0 0 0)',
  },
  logoWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logo: {
    display: 'block',
    height: '72px',
    [theme.breakpoints.up('m')]: {
      height: '96px',
    },
  },
});

export default styles;
