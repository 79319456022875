import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const AboutPayday = ({ classes }) => (
  <Container maxWidth={false}>
    <Box className={classes.textBox} pt={(5, 8, 8)} pb={(5, 8, 8)}>
      <Box pb={[3]}>
        <div className={classes.text}>
          VK – это крупнейший IT холдинг по дневной аудитории в России, в
          котором работает 9 тысяч человек
        </div>
      </Box>
      <div className={classes.separator} />
      <Box pt={[3]}>
        <div className={classes.text}>
          <span className={classes.textAccent}>PayDay</span> – это часть большой
          семьи, которая объединяет в себе такие продукты как Юла, Delivery
          Club, Vkontakte, Одноклассники, Warface и многие другие
        </div>
      </Box>
    </Box>
  </Container>
);

AboutPayday.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutPayday);
